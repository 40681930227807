@import "zen-colors";
@import "zen-base-font-size";

ul {
  margin-top: 1rem;
}

li {
  text-align: left !important;
}

.mat-mdc-dialog-container {
  padding: 1.5rem 2.125rem 2.5rem 2.125rem !important;
  border-radius: 1.5rem !important;
  overflow: hidden !important; // To disable scroll
  margin: 2rem 0 !important;
  max-height: none !important;
  background: white;

  .mdc-dialog__surface {
    box-shadow: none !important;
  }

  .mat-mdc-dialog-content {
    font-size: inherit !important;
    padding: 0px 1rem !important;
    // to disable scroll inside content. Enabled scroll at cdk-overlay-pane(scroll for full dialog)
    overflow: hidden !important;
    max-height: initial !important;
    .note-entry {
      font-size: 1.2rem;
      padding: 1rem;
    }
    @include sharedFontSize;
  }
}

.docusign-panel {
  width: auto !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.25) !important;
}

.cdk-overlay-pane {
  max-width: 100% !important; // to enable full screen width for mobile
}

.zen-dialog {
  position: relative;
  text-align: center;
  overflow-x: hidden;

  .mat-mdc-dialog-title.dialog-title {
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    color: $textColorDark;

    &:before {
      height: 2.25rem;
    }
  }

  .close-icon {
    cursor: pointer;
    color: $textColorDark;
    font-size: 1.75rem;
    padding: 0.5rem;
    z-index: 1;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
  }

  .body-text {
    font-weight: 300 !important;
    font-size: 1.4rem !important;
    margin: 0rem 4rem 2rem 4rem !important;
    color: #40464D !important;
    letter-spacing: 0.1px !important;
    line-height: 1.5 !important;
  }

  .header-icon-main {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    i {
      font-size: 3rem;

      &.success_1 {
        color: $success_2;
      }

      &.danger_1 {
        color: $danger_1;
      }

      &.warn_1 {
        color: $warn_1;
      }

      &.lg-icon {
        font-size: 5rem;
      }

      &.material-symbols-rounded {
        font-variation-settings: 'FILL' 0,
        'wght' 200,
        'GRAD' 0,
        'opsz' 48
      }
    }
  }

  // This class is not changed after ng 15 MDC upgrade
  .mat-dialog-actions, .mat-mdc-dialog-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: auto;
    margin-right: 1.75rem;

    &.center {
      margin-right: 0;
      justify-content: center;
    }
  }

  .dialog-form-sec {
    margin-bottom: 2.5rem !important;

    &.with-border {
      border: 1px solid $textColorLight_2;
      border-radius: 0.57rem;
      padding: 2rem 3.5rem;
    }
  }

  /** Delete confirmation table template used in - pfHelpSvc.getDeleteBodyText() **/
  table.zen-confirm-tbl {
    margin: 1rem 0;

    tr td {
      padding: 0.25rem 1rem !important;

      &.td-icon {
        padding-right: 0rem !important;
        &.ps-0 {
          padding-left: 0rem !important;
        }
        .material-symbols-rounded {
          font-variation-settings: 'FILL' 0,
          'wght' 200,
          'GRAD' 0,
          'opsz' 40
        }
      }

      &.td-title {
        text-transform: capitalize;
        font-weight: 600;
        min-width: 15rem;
      }

      &.td-count {
        font-weight: 600;
        width: 1rem;
      }
    }
  }

}


/* ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT -> mobile-full-height */
/* ZenDialogPanelClassEnum.OPEN_FROM_BOTTOM // to open dialog from bottom. eg., market pulse mobile rates selections */
.mobile-full-height.cdk-overlay-pane, .open-from-bottom.cdk-overlay-pane {
  transition: all 0.5s ease;
  @media (max-width: 462px) {
    .mat-mdc-dialog-container {
      padding: 1.5rem 1rem 2.5rem 1rem !important;

      .mat-mdc-dialog-content {
        padding: 0 2.5rem !important;
      }
    }
  }


  @media only screen and (max-width: 575px) {
    .mat-mdc-dialog-container {
      padding: 1.5rem 0 2.5rem 0 !important;
      border-radius: 0 !important;
      margin: 0 !important;

      .zen-dialog {
        .close-icon {
          right: 1.5rem;
        }

        .dialog-form-sec.with-border {
          border: none;
          padding: 0 2.125rem;
        }

        // This class is not changed after ng 15 MDC upgrade
        .mat-dialog-actions, .mat-mdc-dialog-actions {
          padding: 2rem 0;
          position: fixed;
          bottom: 0rem;
          background: white;
          border-top: 1px solid $textColorLight_2;
          width: 100%;
          justify-content: center;
          z-index: 1;

          &.mobile-fixed-position {
            position: fixed;
            bottom: 0;
            background: white;
            width: 100%;
            left: 0;
            padding: 0 3rem 3rem 3rem;
            box-shadow: 0px 2px 13px 1px rgb(0 0 0 / 10%);
          }
        }
      }

      .mat-mdc-dialog-content {
        max-height: 90vh !important;
        padding-bottom: 2rem !important;
        padding-top: 1rem !important;
        overflow: auto !important;
      }
    }
  }
}

.cdk-overlay-pane {
  position: absolute !important;
  flex-direction: column !important;
  max-height: none !important;
  height: initial !important;

  @media only screen and (max-width: 575px) {
    &.mobile-full-height .mat-mdc-dialog-container {
      height: 100vh !important;
    }
  }
}

.open-from-bottom.cdk-overlay-pane {
  @media only screen and (max-width: 575px) {
    .mat-mdc-dialog-container {
      height: 80vh !important;
      border-top-right-radius: 2rem !important;
      border-top-left-radius: 2rem !important;
    }
  }
}

// Download RCR dialog
.download-rc-report.cdk-overlay-pane {
  .mat-mdc-dialog-container {
    padding: 0 !important;
  }
}

// To hide dialog - Used in the Add LEN option from the Add/Edit serviceAddress dialog
.hide-dialog.cdk-overlay-pane {
  display: none;
}

.dialog-icon, .setting-icon {
  &.material-symbols-rounded {
    font-variation-settings:
      'FILL' 0,
      'wght' 200,
      'GRAD' 0,
      'opsz' 48
  }
}
