@import "zen-colors";

.mat-button-toggle-group {

  &.mat-button-toggle-group-appearance-standard {
    border-color: $textColorLight;
    height: 4.7rem;

    .mat-button-toggle {
      border-color: $textColorLight;
      color: $textColorDark;
      font-size: 1.3rem;
      font-weight: 500;

      button.mat-button-toggle-button {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .mat-ripple-element {
        opacity: 0.5 !important;
        background-color: $textColorLight_2 !important;
      }

      &:hover .mat-button-toggle-button {
        background-color: $textColorLight_3 !important;
      }

      .mat-button-toggle-label-content {
        padding: 0 1.5rem;
      }

      i {
        font-size: 1.75rem;
        color: $textColor;
        line-height: normal;
      }

      &.mat-button-toggle-checked {
        color: $textColorDark;
        background-color: $textColorLight_4;
      }
    }
  }
}
