@use '@angular/material' as mat;


@import "components/zen-colors";

$primaryClr: var(--primaryColor);
$secondaryClr: var(--secondaryColor);

$primary-palette: (
  100: rgba($primaryClr, 0.1),
  200: rgba($primaryClr, 0.2),
  300: rgba($primaryClr, 0.3),
  400: rgba($primaryClr, 0.4),
  500: rgba($primaryClr, 0.5),
  600: rgba($primaryClr, 0.6),
  700: rgba($primaryClr, 0.7),
  800: rgba($primaryClr, 0.8),
  900: rgba($primaryClr, 0.9),
  A100: $primaryClr,
  // ... continues to 900
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  )
);

$secondary-palette: (
  100: rgba($secondaryClr, 0.1),
  200: rgba($secondaryClr, 0.2),
  300: rgba($secondaryClr, 0.3),
  400: rgba($secondaryClr, 0.4),
  500: rgba($secondaryClr, 0.5),
  600: rgba($secondaryClr, 0.6),
  700: rgba($secondaryClr, 0.7),
  800: rgba($secondaryClr, 0.8),
  900: rgba($secondaryClr, 0.9),
  A100: $secondaryClr,
  // ... continues to 900
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  )
);

$my-primary: mat.define-palette($primary-palette, A100);
$my-accent: mat.define-palette($secondary-palette, A100);
/** In future we need to replace this with custom palette colors once it got decided. */
$my-warn: mat.define-palette(mat.$red-palette);

//$ec--mat-typography: mat.define-typography-config(
//  $font-family: $aal-font-family,
//  $headline-4: mat.define-typography-level($aal-font-h1...),
//  $headline-3: mat.define-typography-level($aal-font-h2...),
//);
/* This is the new way of defining a theme from Angular 15 */
$ec--mat-light-theme: mat.define-light-theme((
  color: (
    primary: $primaryClr,
    accent: $secondaryClr,
    warn: $my-warn
  ),
  //typography: $aal--mat-typography,
  density: -1, // you can experiment with densities that suit your application
));

//@include mat.typography-hierarchy($ec--mat-typography);

// Use the default configuration.
$my-typography: mat.define-typography-config();
@include mat.typography-hierarchy($my-typography);


@include mat.core();
/**
  It is important to include all component themes in the body
 */

$zen-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn
  )
));

body {
  @include mat.all-component-themes($zen-theme);
  @include mat.button-density(-2); // these densities make more sense for my project, feel free to experiment
  @include mat.icon-button-density(-2); // these densities make more sense for my project, feel free to experiment
}

 //Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($zen-theme);

// Emit styles for MatButton based on `$zen-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($zen-theme);
@include mat.slide-toggle-theme($zen-theme);
@include mat.dialog-theme($zen-theme);
@include mat.checkbox-theme($zen-theme);
@include mat.checkbox-color($zen-theme);
@include mat.progress-spinner-color($zen-theme);
@include mat.progress-spinner-theme($zen-theme);
@include mat.datepicker-theme($zen-theme);
@include mat.datepicker-color($zen-theme);

//@include mat.progress-bar-theme($zen-theme);
//@include mat.progress-bar-color($zen-theme);
//@include mat.progress-bar-density($zen-theme);

@include mat.button-toggle-color($zen-theme);
@include mat.button-toggle-theme($zen-theme);
@include mat.table-theme($zen-theme);
@include mat.dialog-theme($zen-theme);
@include mat.radio-theme($zen-theme);
@include mat.input-theme($zen-theme);
@include mat.tree-theme($zen-theme);



@import "components/zen-base-font-size";
@import "components/zen-colors";
@import "components/zen-cards";
@import "components/zen-mat-snackbar";
@import "components/zen-mat-menu";
@import "components/zen-mat-checkbox";
@import "components/zen-mat-radio";
@import "components/zen-mat-buttons";
@import "components/zen-mat-slide-toggle";
@import "components/zen-popper";
@import "components/zen-spinner";
@import "components/zen-mat-form";
@import "components/zen-dialog";
@import "components/zen-custom-class";
@import "components/zen-autocomplete";
@import "components/zen-mat-tabs";
@import "components/zen-icon";
@import "components/zen-mat-chips";
@import "components/zen-mat-datepicker";
@import "components/zen-message";
@import "components/zen-breadcrumb";
@import "components/zen-custom-table";
@import "components/zen-mat-button-toggle-group";
@import "zen-shared-style";
@import "components/zen-mat-selection-list";
@import "components/zen-mat-slider"; // For mat-slider custom theme is used in this file
@import "components/zen-details-expansion";
