@import "zen-colors";

.mat-mdc-menu-panel {
  box-shadow: 0px 12px 17px -2px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.75rem !important;
  background: white;
  min-height: unset !important;

  .toggle-sec {
    display: flex;
    align-items: center;
    overflow: hidden;

    .toggle-text {
      color: $textColorDark;
      font-size: 1.2rem;
      margin-right: 0.5rem;
      font-weight: 500;
    }
  }

  .zen-mat-menu-icon {
    font-weight: 300;
    font-size: 1.75rem;
    vertical-align: top;
    margin-right: 0.75rem;
  }

  .mat-mdc-menu-content button:not(.mat-accent):not(.mat-primary), .toggle-sec {
    background: white;
    height: 3.6rem;
    min-height: 3.6rem;

    &:hover {
      background: $textColorLight_4;
    }
  }

  .mat-mdc-menu-content {
    button { // .mat-mdc-menu-item
      display: flex;
      align-items: center;
      font-size: 1.2rem;

      &.divider {
        border-bottom: 0.75px solid $textColorLight_2;
      }

      .custom-sub-icon {
        margin-left: 1rem;
        position: absolute;
        right: 1.25rem;
        font-size: 1.75rem;
        font-weight: 300;
      }
    }
    .mat-mdc-menu-item .mat-mdc-menu-submenu-icon {
      display: none;
    }
  }
}
