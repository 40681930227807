@import "zen-colors";

.mat-mdc-slide-toggle {
  .mdc-form-field {
    button.mdc-switch {
      .mdc-switch__handle {
        background-color: white !important;
        width: 1.25rem;
        height: 1.25rem;
        box-shadow: none;
        left: 0.5rem;

        .mdc-switch__icons svg.mdc-switch__icon path {
          display: none;
        }
      }

      // Unselected
      &.mdc-switch--unselected {
        .mdc-switch__handle {
          left: 0.25rem;
        }

        .mdc-switch__handle::after {
          background: white !important;
          left: 0.1rem;
        }
      }

      .mdc-switch__track {
        border-radius: 1rem;
        height: 1.8rem;
      }
    }

    .mdc-label {
      margin: 0.1rem 0 0 0.75rem;
      font-weight: 300;
      font-size: 1.4rem;
    }
  }

  // Selected/Checked
  &.mat-mdc-slide-toggle-checked {
    .mdc-form-field {
      button.mdc-switch {
        .mdc-switch__track {
          background-color: $success_2 !important;
        }
      }
    }
  }

  &.text-start {
    .mat-slide-toggle-content {
      position: absolute;
      right: 5rem;
    }
  }
}

