@import "components/zen-colors";
/* Ngx pagination design used in New Ui - app-zen-serviceAddress-account-selector-table */
.ngx-pagination {
  margin-bottom: 0 !important;
  padding: 0 !important;

  li {
    font-size: 1.2rem;
  }
  li.pagination-previous a:before, li.pagination-next a:after,
  li.pagination-previous.disabled:before, li.pagination-next.disabled:after {
    display: none;
  }

  li.pagination-previous, li.pagination-next {
    a, span {
      color: $textColorLight_2;
    }
    &:not(.disabled) {
      a, span {
        color: $textColor;
      }
    }
  }

  li a {
    text-decoration: none;
    color: $textColor;
  }

  .current {
    background: var(--secondaryColor) !important;
    border-radius: 4rem !important;
    color: white !important;
    min-width: 2.125rem;
    text-align: center !important;

    span span {
      color: white;
      font-weight: bold;
    }
  }
}
