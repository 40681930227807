@import "zen-colors";

div.zen-details-expansion {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.02);
  border-radius: 2rem;
  border-left: 2rem solid;
  padding: 1rem 4rem;
  position: relative;
  display: flex;
  background: white;

  .detail-value {
    font-weight: 400;
    font-size: 1.4rem;
    color: $textColorDark;
  }

  &.current {
    border-left-color: $success_2;
  }

  &.future {
    border-left-color: $success_3;
  }

  &.processing {
    border-left-color: $info_1;
  }

  &.expired {
    border-left-color: $danger_1;
  }

  &.secondary-color {
    border-left-color: $secondaryClr;
  }

  &.draft {
    border-left: 2rem solid transparent;
    border-image-source: repeating-linear-gradient(
        -50deg,
        $textColorLight_2,
        $textColorLight_2 1.2rem, /* Adjust the grey stripe size */
        transparent .8rem, /* Adjust the white stripe size */
        transparent 2rem /* Total size of one stripe pattern */
    );
    border-image-slice: 2%;
    -webkit-mask: /*4*/
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: inherit;
  }


  div.details-left {
    width: 25%;
    padding-left: 1rem;
    padding-top: 3rem;
  }

  img.details-logo {
    max-height: 5rem;
  }

  h1.details-rate {
    font-size: 3rem;
    font-weight: 300;

    .small {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  div.details-right {
    width: 80%;
  }

  div.details-list {
    ul {
      list-style-type: none;

      li.detail-main {
        padding: 2rem;

        &.large-detail {
          width: 32%;
        }

        &.medium-detail {
          width: 25%;
        }

        &.small-detail {
          width: 15%;
        }
      }
    }
  }


  .expansion-action-btn-container {
    position: absolute;
    right: -1.5rem;
    bottom: 0.5rem;

    @media only screen and (max-width: 1023px) {
      position: fixed;
      left: 0;
      bottom: 0rem;
      z-index: 2;
      background: white;
      padding: 2rem;
      width: 100%;
      text-align: center;
      border-top-right-radius: 2rem;
      border-top-left-radius: 2rem;
      box-shadow: 0px 2px 13px 1px rgb(0 0 0 / 10%);
    }
  }

  &.expanded {
    transition: all 0.5s;
    height: 100%;
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 10rem 2rem 2rem 6rem;
    margin: 0 !important;
    border-radius: 0;
    border: none;
    overflow-y: scroll;

    .mobile-close-left-icon {
      display: block;
    }

    button.det-menu-icon {
      z-index: 1;
      border-radius: 2rem;
      padding: 2rem;
      box-shadow: 0px 2px 13px 1px rgb(0 0 0 / 10%);
    }
  }
}

:host ::ng-deep {
  // Hide menu gray circle overlay on click
  span.mat-mdc-focus-indicator {
    display: none !important;
  }
}

button.det-menu-icon {
  position: absolute;
  right: 2.5rem;
  top: 2rem;
  color: $textColorLight;
}

.details-expansion.mat-expansion-panel {
  overflow: visible !important;
  box-shadow: none !important;

  .mat-expansion-panel-body {
    padding: 0;

    .det-exp-wrapper .sub-details {
      display: flex;
      margin-top: 1rem;
      flex-wrap: wrap;
      align-items: self-end;

      p.p-value {
        font-size: 1.4rem;
        font-weight: 500;
        margin: 0;
        word-break: break-word;
      }

      .sub-det-child {
        margin: 1.5rem 0;
        padding: 0 2rem 0 1rem;
      }

    }
  }
}

.mobile-close-left-icon {
  position: absolute;
  top: 4.5rem;
  cursor: pointer;
  left: 2.145rem;
  display: none;
}

button.btn-expand {
  font-size: 1.2rem;
  color: $blue_1;
  display: none;
}

// At 1250px, make detail list items 50% width, and adjust sizing
@media (max-width: 1400px) {
  div.details-left {
    width: 25% !important;
    padding-left: 0rem;
  }
}

@media (max-width: 1250px) {
  div.zen-details-expansion {
    align-items: start !important;
  }

  div.details-left {
    width: 25% !important;
    padding-left: 0rem !important;
    padding-top: 2rem;
  }

  div.details-right {
    width: 75% !important;
    ul {
      align-items: flex-start !important;
    }
  }

  li.detail-main {
    padding: 2rem !important;
  }
}

// iPad vertical, 728-1023px
@media (max-width: 1023px) {
  div.details-left {
    width: 60% !important;
  }

  div.details-right {
    width: 40% !important;
  }

  li.detail-main {
    &.large-detail, &.medium-detail {
      width: 100% !important;
      padding: 2rem 0 !important;
    }
    &.small-detail {
      padding: 0.25rem 0 2rem 0 !important;
    }
  }
}


// Mobile, <=727px
@media (max-width: 727px) {
  .zen-details-expansion {
    padding-top: 2.5rem !important;
    padding-bottom: 3rem !important;
  }

  button.btn-expand {
    display: flex !important;
    justify-content: center;
    padding: 2rem !important;
  }

  div.details-right {
    display: none;
  }

  li.detail-main {
    &.large-detail, &.medium-detail, &.small-detail {
      order: initial;
    }
  }
}

.icon-padding {
  padding: 1rem !important;
}
