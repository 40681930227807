@import "zen-colors";

/* .To avoid text wrap */
.no-wrap {
  white-space: nowrap;
  overflow: hidden;
}

.border-radius-1 {
  border-radius: 1rem !important;
}

.border-radius-2 {
  border-radius: 2rem !important;
}

.no-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden
}

// Used in Matrix Pricing Status Report for -> Market Pulse. Apply bg color
.zen-progress-status {
  padding: 0.25rem 1rem;
  border-radius: 2rem;
  color: white;
  min-width: 11rem;
  text-align: center;
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;

  &.legend {
    max-width: 0.25rem;
    padding: 0.45rem;
    border-radius: 0;
    margin-right: 0.5rem;
  }


  /** The below style classes are w.r.t ProgressStatusCls */
  &.pending {
    background: $info_1;
  }
  &.approved {
    background: $success_2;
  }
  &.ineligible {
    background: $warn_1;
  }
  &.not-received {
    background: $danger_1;
  }
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-sub {
  vertical-align: sub;
}


.math-fees {
  font-size: 1.4rem;
  font-weight: 300;
  display: block;
  .fee-row {
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    .fee-value { display: inline-block; width: 75px; }
    .fee-label { display: inline-block; width: 120px; text-align: left;
      color: #696969; padding-left: .5em;
    }
  }
  .sign-space { display: inline-block; width: 20px; text-align: right }
  .math-separator { clear: both; border-bottom: 1px solid #000; margin: 3px -3px 3px 10px }
}



// This class is used across the platform to position the <app-zen-tab-top-action> component, new design.
.zen-top-action-wrapper-inside-mat-tab {
  scale: 0.9;
  position: relative;
  top: -5.9rem;
  right: -5.25rem;
  z-index: 10;

  &.position-v2 {
    top: 1.65rem;
  }

  @media screen and (max-width: 1100px) {
    right: -4rem;
  }

  @media screen and (max-width: 850px) {
    right: -1.75rem;
  }
}

.dropdown-option-icon {
  color: $success_2;
  font-size: 1.5rem;

  &.option-position {
    position: absolute;
    top: 25%;
    left: 0.25rem;
  }

  &.material-symbols-rounded {
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 40
  }
}

.pointer-events-none {
  pointer-events: none;
}

.panel-min-wd-15 {
  min-width: 15rem !important;
}

.panel-min-wd-20 {
  min-width: 20rem;
}

.panel-min-wd-25 {
  min-width: 25rem;
}

.min-wd-auto {
  min-width: auto;
}

// Send RCR and Request contract dialog
.sr-table-and-selection-border {
  border: 2px solid $textColorLight_3;
  border-radius: 1rem;

  .sr-zen-table-border {
    border-right: 2px solid $textColorLight_3;

    .zen-mat-table .zen-mat-table-wrapper {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .add-reviewer-btn-wrapper {
      text-align: center;
      padding: 2rem;
      border-bottom: 2px solid $textColorLight_3;

      &.selected {
        border-left: 0.5rem solid $blue_1;
      }
    }
  }

  .sr-action-section {
    padding: 8rem 2rem;
    position: relative;

    .sr-len-pagination-main {
      position: absolute;
      right: 0rem;
      top: -3rem;
      display: flex;
    }

    &.form-enabled {
      padding: 3rem;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.count {
  padding: 0.25rem;
  background: $blue_fade_1;
  color: white;
  min-width: 2.25rem;
  height: 2.25rem;
  border-radius: 2rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-weight: 600 !important;
  text-align: center;
  font-size: 1.2rem;
}
