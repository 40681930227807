@import "zen-colors";

.zen-message {
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  color: $textColor;
  background: $textColorLight_4;
  border-color: $textColorLight_4;

  .message-text {
    text-align: left;
    a {
      text-decoration: underline;
      font-weight: 400;
    }
  }

  i {
    font-size: 1.6rem;
  }

  &.success {
    border-color: $success_4;
    color: $success_1;
    background: $success_4;
    .message-text, .message-text a {
      color: $success_1;
    }
  }

  &.error {
    border-color: $danger_1;
    color: $danger_1;
    background: $danger_5;
    .message-text, .message-text a {
      color: $danger_1;
    }
  }

  &.warn, &.warning {
    border-color: transparent;
    color: $warn_1;
    background: #FF993A33;
    .message-text, .message-text a {
      color: $warn_1;
    }
  }
  &.pending, &.info {
    border-color: transparent;
    color: $blue_1;
    background: $blue_5;
    .message-text, .message-text a {
      color: $blue_1;
    }
  }
}
