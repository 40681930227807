@import "zen-colors";
$borderWidth: 1px;
$borderRadius: 4px;

/* mat-checkbox design */
.mat-mdc-checkbox {

  .mdc-form-field {
    color: $textColorDark !important;
    display: flex;
    align-items: center !important;
  }

  .mdc-checkbox {
    padding: 0 1rem 0 0;

    .mdc-checkbox__background {
      top: auto;
      left: 0;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 0.45rem;
      border-width: $borderWidth;
      border-color: $textColorLight !important;

      svg.mdc-checkbox__checkmark {
        color: white !important;
        path.mdc-checkbox__checkmark-path {
          stroke-width: 0.2rem;
        }
      }
      div.mdc-checkbox__mixedmark {
        border-color: white !important;
      }
    }

    .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
      --mdc-checkbox-unselected-focus-state-layer-color: #A8B8C2;
      --mdc-checkbox-selected-hover-state-layer-color: #A8B8C2;
      --mdc-checkbox-selected-pressed-state-layer-color: #A8B8C2;
      --mdc-checkbox-unselected-hover-state-layer-color: #A8B8C2;
      --mdc-checkbox-unselected-pressed-state-layer-color: #A8B8C2;

      display: none;
    }
    &:hover {
      .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
        display: block;
        top: -1rem;
        left: -1rem;
        width: 3.75rem;
        height: 3.75rem;
      }
    }

    .mat-mdc-checkbox-touch-target, .mdc-checkbox__native-control {
      width: 2rem;
      height: 2rem;
    }

    // mat-checkbox position adjustment
    .mat-mdc-checkbox-touch-target {
      left: 1rem;
    }
  }

  .mdc-form-field {
    align-items: flex-start;

    .mdc-label {
      color: $textColorDark;
      font-size: 1.4rem;
      margin: 0.125rem 0 0 0;
    }
  }

  &.mat-checkbox-child {
    margin-left: 2.575rem;

    .mdc-label {
      font-size: 1.2rem;
      font-weight: 400;
      padding-left: 1.2rem;
    }
  }

  &.mat-checkbox-parent {
    padding-left: 0rem;
    margin-left: 0rem;

    .mdc-checkbox__background {
      display: none;
    }
  }


  &.meter-status-sub-header {
    .mdc-checkbox__background {
      display: none !important;
      padding-left: 0rem;
      margin-left: 0rem;
    }

    .mdc-label {
      font-size: 1.2rem;
      padding-left: 0rem;
    }
  }

  &.ng-invalid {

    .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
      display: none !important;
    }

    .mdc-checkbox__background {
      border: 1px solid $danger_1 !important;
    }

    &.mat-mdc-checkbox-checked {

      .mdc-checkbox__background {
        background-color: transparent !important;

        svg.mdc-checkbox__checkmark path.mdc-checkbox__checkmark-path {
          stroke: $danger_1 !important;
        }
      }
    }
  }
}


/* mat-checkbox disabled design */
.mat-mdc-checkbox-disabled.mdc-checkbox--disabled {
  opacity: 1 !important;

  .mdc-form-field .mdc-label {
    color: $textColorLight !important;
  }

  &.mat-checkbox-child {
    margin-left: 2.575rem !important;

    .mdc-label {
      font-size: 1.2rem;
      font-weight: 400;
      padding-left: 1.2rem;
    }
  }

  &.mat-pseudo-checkbox-indeterminate.mat-accent  &.mat-mdc-checkbox-checked.mat-accent {
    background-color: $textColorLight;
  }

  .mdc-checkbox .mdc-checkbox__background {
    margin-right: 0;
    border-color: $textColorLight_2 !important;
    background-color: $textColorLight_4 !important;

    .mdc-checkbox__checkmark {
      background-color: $textColorLight !important;
    }
  }

  &.mat-checkbox-parent {
    padding-left: 0rem;
    margin-left: 0rem;

    .mdc-checkbox__background {
      display: none;
    }
  }

  &.mat-checkbox-child {
    margin-left: 1rem;

    .mdc-label {
      font-size: 1.2rem;
      font-weight: 400;
      padding-left: 1.2rem;
      margin: 0;
    }
  }
}

.mat-checkbox-parent-hidden {
  padding-left: 0rem;
  display: none;
  cursor: default !important;
}

/* This checkbox is used inside multi-select dropdown */
.mat-pseudo-checkbox {
  border-width: $borderWidth !important;
  border-radius: $borderRadius !important;
  border-color: $textColorLight !important;
  &::after {
    top: 3px !important;
    left: 2px !important;
  }
  &.mat-pseudo-checkbox-checked {
    border-color: transparent !important;
  }
}
