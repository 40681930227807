@use '@angular/material' as mat;

@include mat.core();

@import "zen-colors";

$slider-primary-palette: (
  100: rgba($primaryClr, 0.1),
  200: rgba($primaryClr, 0.2),
  300: rgba($primaryClr, 0.3),
  400: rgba($primaryClr, 0.4),
  500: rgba($primaryClr, 0.5),
  600: rgba($primaryClr, 0.6),
  700: rgba($primaryClr, 0.7),
  800: rgba($primaryClr, 0.8),
  900: rgba($primaryClr, 0.9),
  A100: $primaryClr,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white
  )
);

$slider-primary: mat.define-palette($slider-primary-palette, A100);

$textColorLight_2-palette: (
  100: rgba($textColorLight_2, 0.1),
  200: rgba($textColorLight_2, 0.2),
  300: rgba($textColorLight_2, 0.3),
  400: rgba($textColorLight_2, 0.4),
  500: rgba($textColorLight_2, 0.5),
  600: rgba($textColorLight_2, 0.6),
  700: rgba($textColorLight_2, 0.7),
  800: rgba($textColorLight_2, 0.8),
  900: rgba($textColorLight_2, 0.9),
  A100: $textColorLight_2,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white
  )
);

$slider-accent: mat.define-palette($textColorLight_2-palette, A100);

$slider-theme: mat.define-light-theme((
  color: (
    primary: $slider-primary,
    accent: $slider-accent
  )
));

@include mat.slider-theme($slider-theme);


.mat-mdc-slider {
  width: 100% !important;
  margin: 0 !important;
  --mdc-slider-inactive-track-height: 1.25rem;
  --mdc-slider-active-track-height: 1.25rem;
  // knob width & height
  --mdc-slider-handle-width: 2.75rem;
  --mdc-slider-handle-height: 2.75rem;

  --mdc-slider-with-tick-marks-inactive-container-opacity: 0;

  --mat-slider-value-indicator-opacity: 1;
  --mat-slider-value-indicator-height: 2.5rem;
  --mat-slider-value-indicator-border-radius: 10rem;
  --mdc-slider-label-container-color: $textColor;

  // Disable state
  --mdc-slider-disabled-inactive-track-color: #D7E0E4;
  --mdc-slider-disabled-active-track-color: #D7E0E4;
  --mdc-slider-disabled-handle-color: #fff;

  .mdc-slider__value-indicator-container {
    white-space: nowrap;
    overflow: hidden;
    text-align: center;

    .mdc-slider__value-indicator {
      background-color: white;
      border: 1px solid $textColorLight_2;
      color: $textColor !important;

      // Down arrow caret position adjustment.
      &:before {
        bottom: -0.6rem;
      }
    }
  }

  .mdc-slider__thumb-knob {
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $textColorDark;
    border: 1px solid $textColorLight_2 !important;
  }

  &.mdc-slider--disabled {
    .mdc-slider__thumb-knob {
      box-shadow: none;
    }
    .mdc-slider__track--inactive {
      opacity: 1 !important;
    }
  }

  // To make it readonly add class="slider-readonly" and disabled flag
  // <mat-slider color="error-success" class="slider-readonly" disabled>
  &:not(.mdc-slider--disabled), &.mdc-slider--disabled.slider-readonly {
    &.slider-readonly {
      opacity: 1;
    }

    // knob design updates
    &.mat-success, &.mat-error-success {
      --mdc-slider-handle-color: white;
      --mdc-slider-focus-handle-color: white;
      --mdc-slider-hover-handle-color: white;
    }

    &.mat-success {
      .mdc-slider__track--inactive {
        opacity: 1;
        background: linear-gradient(to right, #D9FFEC, #54E2C0); // $success_6 to $success_2
      }

      .mdc-slider__track--active {
        display: none;
      }
    }

    &.mat-error-success {
      .mdc-slider__track--inactive {
        opacity: 1;
        background: linear-gradient(to right, #FA868D, #FCCED0, #D9FFEC, #54E2C0);
      }

      .mdc-slider__track--active_fill {
        display: none;
      }
    }
  }

}
