@import "zen-colors";

.mat-mdc-chip-listbox {
  .mat-mdc-chip.mat-mdc-standard-chip {
    border-radius: 0.5rem !important;
    background-color: $textColorLight_4 !important;
    margin: 0.325rem !important;

    .mdc-evolution-chip__text-label {
      padding: 0.125rem 0.75rem;
    }

    &.mdc-evolution-chip--selectable {

      .mat-mdc-chip-remove {
        padding: 0 0.25rem 0 0.25rem !important;
        top: -2px !important;
        .pi-chip-remove-icon {
          font-size: 1.25rem;
          top: 0.1rem;
          position: relative;
        }
      }
    }
  }
}

.mat-mdc-chip {
  font-size: 1rem;
  border-radius: 4px !important;
  background: rgba(168, 184, 194, 0.1);
  max-height: none !important;
  min-height: 0 !important;
  height: 1.8rem !important;

  &.mdc-evolution-chip {
    margin: 0 !important;
  }

  &.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
    padding: 0;
  }

  &.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
    padding-left: 0.5rem;
  }

  .mdc-evolution-chip__graphic {
    display: none;
  }

  &.active,&.inactive {
    font-size: 0.8572rem;
    padding: 0.15rem 1rem 0.275rem 1rem !important;
    border-radius: 1rem !important;
  }
  &.active {
    background: $success_4 !important;
    .mdc-evolution-chip__text-label {
      color: $success_1 !important;
    }
  }
  &.inactive {
    background: $textColorLight_3 !important;
    .mdc-evolution-chip__text-label {
      color: $textColorLight !important;
    }
  }
  &.sm {
    min-height: 1.375rem !important;
  }

  /* For use on Contract Details page */
  &.current, &.future, &.processing, &.expired {
    padding: 2px 11px !important;
    border-radius: 1rem !important;
    font-size: 1.25rem;
    line-height: 16px;
  }
  &.current {
    background: $success_2 !important;
    .mdc-evolution-chip__text-label {
      color: white !important;
    }
  }
  &.future {
    background: $success_3 !important;
    .mdc-evolution-chip__text-label {
      color: white !important;
    }
  }
  &.processing {
    background: $info_1 !important;
    .mdc-evolution-chip__text-label {
      color: white !important;
    }
  }
  &.expired {
    background: $danger_1 !important;
    .mdc-evolution-chip__text-label {
      color: white !important;
    }
  }
  &.draft {
    background: $textColorLight !important;
    border-radius: 1rem !important;
    .mdc-evolution-chip__text-label {
      color: white !important;
      padding-right: 1rem;
    }
  }


}

.filter-chip.mat-mdc-chip {
  font-size: 1rem;
  color: $textColorDark !important;
  padding: 0 !important;
  border-radius: 2px !important;
  height: 1.75rem !important;
  min-height: 1.75rem !important;
  background-color: $textColorLight_4 !important;
  box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.05) !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;

  &.mat-mdc-standard-chip .mat-mdc-chip-remove.mdc-evolution-chip__icon--trailing {
    font-size: 1.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

.mat-mdc-chip.select-chip {
  background: transparent !important;
  cursor: pointer;

  &.selected {
    background-color: $textColorLight_2 !important;
    border-radius: 2rem !important;
  }
}

.filter-chip-list-item.mat-mdc-chip {
  display: flex !important;
  align-items: center;
  font-size: 1rem;
  color: $textColorDark !important;
  border-radius: 2px !important;
  min-height: 1.63rem !important;
  line-height: 1;
  background: white;
  padding-right: .5rem !important;
  padding-left: .5rem !important;
  padding-top: .4rem !important;
  padding-bottom: .4rem !important;
  margin: 0px !important;
  &:hover {
    background-color: $textColorLight_4 !important;
  }
}
