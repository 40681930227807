@import "zen-colors";

.mat-mdc-tab-group {
  .mat-mdc-tab .mdc-tab__text-label {
    --mat-tab-header-inactive-label-text-color: #A8B8C2; // $textColorLight
    --mat-tab-header-inactive-hover-label-text-color: #40464D;
  }

  /* This v1 version is used on the portfolio tabs component */
  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-mdc-tab-header {
    margin-bottom: 2.5rem !important;
    .mat-mdc-tab-header-pagination {
      box-shadow: none !important;
      margin: 0 0 1.5rem 0 !important;
      height: 2.5rem;
      width: 2rem;
      border-radius: 50%;

      &.mat-mdc-tab-header-pagination-disabled {
        display: none;
      }
    }

    .mat-mdc-tab-label-container {
      // To remove bottom border below all tabs
      flex-grow: 0;
      border: none;
    }

    .mdc-tab {
      opacity: 1 !important;
      min-width: 8rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-weight: 600;
      margin-right: 1.25rem;
      padding-bottom: 1.5rem;
      color: $textColor;
      position: relative;
      border-radius: 5rem;

      .mdc-tab__ripple {
        display: none;
      }

      .tab-count {
        color: white;
        background: $danger_1;
        border-radius: 1rem;
        height: 1.75rem;
        width: 1.75rem;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        margin-left: 0.5rem;
      }
    }
  }

  &.v2 {
    &.header-bg-white .mat-mdc-tab-header {
      background: white;
      padding: 0.75rem 1rem;
      border-radius: 1.5rem;
      margin-bottom: 1rem !important;
    }

    .mat-mdc-tab-header {

      .mat-mdc-tab-header-pagination {
        margin: 1.25rem 0 0 0 !important;
        &.mat-mdc-tab-header-pagination-disabled {
          display: none;
        }
      }

      .mat-mdc-tab-label-container {

        .mdc-tab {
          padding-bottom: 0;
          .mdc-tab__text-label {
            font-weight: 500;
            padding: 0.625rem 1.5rem;

            p {
              color: $textColorLight;
              display: flex;
              align-items: center;
              font-size: 1.4rem;
            }

            &:after {
              content: none;
              position: relative;
            }
          }

          &.mdc-tab--active {
            .mdc-tab-indicator {
              display: none;
            }

            /* Design for underscore for selected item. */
            .mdc-tab__text-label {
              p {
                color: white;
                display: flex;
                align-items: center;
                font-size: 1.4rem;
              }

              background: var(--secondaryColor);
              color: white;
              border-radius: 2rem;
            }
          }
        }
      }

      /* Reduce the mat-tab-label width if screen size < 528px */
      @media only screen and (max-width: 528px) {

        .mat-mdc-tab-label-container .mdc-tab {
          min-width: 6.75rem;

          &:not(.mdc-tab--active) .mdc-tab__text-label {
            padding: 0.5rem 0.75rem 0.5rem 1rem;
          }
        }
      }

      @media only screen and (min-width: 728px) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }


  &.v1 {

    .mat-mdc-tab-header .mdc-tab {

      &.mdc-tab--active {
        .mdc-tab-indicator {
          display: flex !important;

          .mdc-tab-indicator__content--underline {
            border-top-width: 0.5rem !important;
            border-radius: 1rem !important;
          }
        }

        /* Design for underscore for selected item. */
        .mdc-tab__text-label {
          position: relative;
          background: none !important;
          color: $textColorDark !important;
          font-weight: 700;
          padding: 0.5rem 0;
          font-size: 1.4rem;
        }
      }
    }
  }

  &.sm-tab-size {
    .mat-mdc-tab-header .mdc-tab {
      margin-right: 0;
      padding-right: 0;
    }
  }

  &.tab-header-bg {
    .mat-mdc-tab-header {
      margin: 1rem !important;
      background: white;
      padding: 0.5rem 1rem 0.5rem 2rem;
      border-radius: 1.5rem;
    }
  }

  &.no-scroll {
    .mat-mdc-tab-body-content {
      overflow-y: hidden !important;
    }
  }
}


.zen-tab-title {
  display: grid;
  position: relative;
  font-weight: 700 !important;
  text-align: left;
  margin-bottom: 3.75rem !important;
  font-size: 1.4rem !important;

  &:after {
    content: ".";
    color: transparent;
    background: var(--secondaryColor);
    max-height: 0.5rem;
    width: 8rem;
    border-radius: 1rem;
    position: absolute;
    top: 3.125rem;
    min-width: 14rem;
  }
}
