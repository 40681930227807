@import "zen-colors";

$borderRadius: 5px;
$formFontSize: 1.2rem;

$scrollBarWidth: 1.4rem;

@mixin ZenCustomScrollBar {
  &::-webkit-scrollbar {
    width: $scrollBarWidth;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $textColorLight_2;
    border-radius: 1rem;
    border: 0.4rem solid white;
  }
}

/* Mat-form-field design STARTS */
/* Using this custom class only for labels - to avoid conflicting with existing primeng form designs. */
.form-title {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.locked-input-value {
  margin: 0.25rem 0 1.25rem 1rem;
  font-size: 1.2rem;

  .material-symbols-rounded {
    font-size: 1.6rem;
    font-variation-settings:
      'FILL' 0,
      'wght' 200,
      'GRAD' 0,
      'opsz' 48
  }
}

.mat-mdc-form-field-hint {
  float: right;

  .material-symbols-rounded {
    font-size: inherit;
    font-variation-settings: 'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 48
  }

  &.info {
    color: $blue_1;
  }
}

@keyframes delayChangeColor {
  to {
    color: $danger_1;
  }
}

@keyframes delayChangeBorderColor {
  to {
    border-color: $danger_1;
  }
}

.mat-mdc-form-field {
  line-height: initial;

  input.mat-mdc-input-element {
    //line-height: 1;
    font-size: $formFontSize;
    min-height: 4rem !important; // For better input spacing
    position: relative;
    top: -1rem;

    /* Remove Arrows/Spinners */
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  &.mat-form-field-appearance-standard {
    width: 100%;
    border: 1px solid $textColorLight;
    border-radius: $borderRadius;
    padding: 0 1.5rem;

    .mat-mdc-form-field-flex {
      padding-top: 0em;
    }

    .mat-mdc-text-field-wrapper {
      padding-bottom: 0.65em;
    }

    .mat-mdc-select-placeholder {
      color: $textColorLight;
      font-weight: 300;
      font-size: 1.4rem;
    }

    &.ng-invalid.submitted {
      /* Hide placeholder on error. */
      ::placeholder {
        color: transparent !important;
      }
    }
  }

  /* appearance=outline STARTS */
  &.mat-form-field-appearance-outline {
    max-width: 100%;
    min-width: 100%;

    --mdc-outlined-text-field-input-text-color: #40464D; // $textColorDark Input field text color
    --mat-select-enabled-trigger-text-color: #40464D; // $textColorDark Dropdown text color
    --mdc-outlined-text-field-hover-outline-color: #8597A2; // $textColor

    // mdc-notched-outline border configs
    --mdc-outlined-text-field-outline-color: #A8B8C2; // $textColorLight - mdc-notched-outline default borderColor
    --mdc-outlined-text-field-error-outline-color: #FF7D7D; // $danger_1 - mdc-notched-outline invalid borderColor
    --mdc-outlined-text-field-outline-width: 1px; // mdc-notched-outline  border width

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
      font-size: 1.2rem; // Font size for input/textarea
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;
    }

    // Removing max-height constraint for input with mat-chip selection - used to support app-zen-input-chip-list-selection
    &:not(.mat-form-field-type-mat-chip-list.value-exists) {

      .mat-mdc-text-field-wrapper {
        max-height: 4.75rem;
      }

      .mat-mdc-form-field-infix {
        max-height: 5rem;
      }
    }

    // Setting height for term selection field
    &.mat-form-field-type-mat-chip-list.value-exists .mat-mdc-form-field-infix {
      height: 7.5rem;
    }

    .mat-mdc-text-field-wrapper {

      .mdc-notched-outline__notch {
        .mat-mdc-floating-label.mdc-floating-label {
          top: 2.25rem;

          &.mdc-floating-label--float-above {
            top: 3rem;
            font-size: 1.2rem;
          }

          .mat-mdc-form-field-required-marker {
            display: none;
          }
        }
      }
    }

    .mat-mdc-form-field-infix {
      z-index: 1; // To display text
    }

    // mat-chip selection - used to support app-zen-input-chip-list-selection
    &.mat-form-field-type-mat-chip-list {

      &.ng-invalid.ng-touched {
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch {
          //border-color: var(--mdc-outlined-text-field-error-outline-color);
          animation: delayChangeBorderColor 1ms linear 0.25s forwards;
        }
      }

      input.mat-mdc-input-element {
        max-width: 5rem;
        min-width: 5rem;
      }
    }

    .mat-mdc-form-field-flex {
      padding: 0 1rem 0 1.5rem;
      border: none !important;
      box-sizing: inherit !important;
    }

    &.thin .mat-mdc-form-field-flex {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .mat-mdc-form-field-subscript-wrapper {
      padding: 0 !important;
      height: 1rem;

      .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        top: -0.2rem;
      }
    }

    .mat-mdc-form-field-infix {
      .mat-datepicker-toggle {
        top: -0.25rem;
        right: 0rem;
        position: absolute;
      }

      ::placeholder {
        color: $textColorLight !important;
      }
    }

    &.thin .mat-mdc-form-field-infix {
      border-top: 0.24375em solid transparent !important;
    }

    .mat-mdc-form-field-icon-suffix {
      top: -0.25rem;

      i {
        font-size: 1.5rem;
        vertical-align: super;
      }
    }

    .mat-mdc-text-field-wrapper, &.mat-focused .mat-mdc-text-field-wrapper {
      .mdc-floating-label {
        font-size: $formFontSize;
        color: $textColorLight;
        line-height: initial;
      }
    }

    // Hiding default dropdown arrow and replacing it with
    // <i class="material-symbols-rounded" matSuffix>expand_more</i>
    .mat-mdc-select-arrow {
      color: white;
      display: none;
    }
    .mat-mdc-select-value {
      font-size: $formFontSize;
    }

    .mdc-notched-outline {
      background: white;
      border-radius: $borderRadius;

      .mdc-notched-outline__leading {
        min-width: 1rem;
      }
    }


    // On input focused
    &.mat-focused .mdc-notched-outline {
      .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
        border-color: $textColorLight;
      }
    }

    &.mat-mdc-form-field-label-always-float .mdc-notched-outline .mdc-notched-outline__notch {
      border-top-color: transparent !important;
    }

    /* appearance=outline INVALID state */
    &.ng-invalid.submitted, &.ng-invalid.ng-touched {
      .mat-mdc-text-field-wrapper .mdc-floating-label, .mat-mdc-select {
        // DEV-10771 - to fix - label and border of the form field, quickly shows and error before going back to steady state.
        animation: delayChangeColor 1ms linear 0.25s forwards;
      }

      .mdc-notched-outline {
        .mdc-notched-outline__leading, .mdc-notched-outline__trailing, .mdc-notched-outline__notch {
          // DEV-10771 - to fix - label and border of the form field, quickly shows and error before going back to steady state.
          animation: delayChangeBorderColor 1ms linear 0.25s forwards;
        }
      }
    }

    /* appearance=outline DISABLED state */
    &.mat-mdc-form-field-disabled, &.mat-form-field-disabled, &.locked {
      pointer-events: none;
      .mdc-notched-outline {
        background: $textColorLight_4 !important;
      }

      .mat-mdc-form-field-infix {
        input, textarea {
          color: $textColorLight !important;
        }

        .mat-mdc-select {
          & > div {
            width: 100%;

            .mat-mdc-select-value {
              width: 100%;
              span.mat-mdc-select-value-text {
                width: 100%;
                display: inline-block;

                span {
                  width: 100%;
                  display: inline-block;
                  overflow: hidden;
                }
              }
            }
          }
        }

        .mat-mdc-select-value {
          overflow: inherit !important;
          color: $textColorLight !important;
        }
      }

      &:not(.locked) {
        // To hide dropdown arrow on - Disable/Locked state
        .mat-mdc-select .mat-mdc-select-arrow-wrapper {
          display: none;
        }
      }

      .mat-mdc-form-field-icon-suffix {

        i {
          color: $textColorLight;
        }
      }

      .mat-mdc-select-arrow-wrapper {
        color: $textColorLight !important;
      }
    }

    // UPLOAD FILES
    &.input-upload-files .mat-mdc-text-field-wrapper {
      pointer-events: none;
      cursor: pointer;
    }
  }


  // Mat select without mat-label
  &.form-field-without-label {
    .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
      padding: 0;
      width: 0 !important;
    }

    .mat-mdc-select, .mat-mdc-input-element {
      position: relative;
      top: -1.25rem;
    }

    .prefix-icon {
      position: relative;
      top: -0.25rem;
    }
  }
}

// Textarea input design -> zen-mat-textarea
.zen-mat-textarea.mat-mdc-form-field.mat-form-field-appearance-outline:not(.mat-form-field-type-mat-chip-list) {
  .mat-mdc-text-field-wrapper, .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix  {
    max-height: max-content;
  }
}


.zen-input-group {
  display: flex;
  align-items: center;

  .left-field {
    width: 100%;
  }

  .right-field {
    position: relative;
    right: 1px;

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
      max-width: 8.75rem;

      .mat-mdc-form-field-infix {
        .mat-mdc-select {

          .mat-mdc-select-trigger .mat-mdc-select-value {
            min-width: 5rem;
          }
        }
      }
    }

    // Locked state
    &.locked, &.mat-form-field-disabled {
      .mat-mdc-form-field-infix .mat-mdc-select {

        .mat-mdc-select-value {
          overflow: inherit;
          color: $textColorLight !important;
        }
      }
    }

    &.btn-action {
      border: 1px solid $textColorLight;
      padding: 1.3rem;
      height: 4.75rem;
      position: relative;
      right: 1px;
      top: -0.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: white;
      font-size: 1.75rem;

      &.btn-action-disabled {
        background: $textColorLight_3;
        cursor: default;
      }

      &:hover {
        border-width: 0.2rem;
        padding: 1.185rem;
        border-left-width: 2px;
      }

      &:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        left: -0.2rem;
        &:hover {
          left: -0.1rem;
        }
      }
    }
  }

  .left-field, .right-field {
    min-width: auto;

    .mat-mdc-form-field-flex .mat-form-field-prefix {
      position: absolute;
      top: 1.2rem;
    }
  }

  .left-field .mdc-notched-outline .mdc-notched-outline__trailing,
  .right-field .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 0 !important;
  }

  .right-field.mat-form-field-disabled:not(.locked) .mat-mdc-form-field-flex {
    padding-left: 2.25rem !important;
  }
}

.mat-mdc-form-field-error, .mat-mdc-form-field-hint {
  font-size: 1rem;
}

.mat-mdc-form-field-error {
  color: $danger_1;
  text-align: left;

  &.outside-mat-form-field {
    position: absolute;
    top: 4.5rem;
  }
}

.edit-icon {
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1.5rem;
  color: var(--secondaryColor);
}

.info-icon {
  color: $blue_1;
  cursor: pointer;

  &.sm {
    font-size: 1.2rem;
  }
}

/* Mat-form-field design ENDS */


/* Material select dropdown panel design overrides STARTS. */
.mat-mdc-select-panel, .mat-mdc-autocomplete-panel {
  background: white !important;
  border-radius: $borderRadius !important;
  box-shadow: 0 1rem 1.5rem 0.5rem rgba(0, 0, 0, 0.1) !important;
  padding: 0.75rem 0 0.75rem $scrollBarWidth !important; // leaving right padding as 0 to support ZenCustomScrollBar
  overflow-y: scroll !important;

  @include ZenCustomScrollBar;


  .mat-mdc-optgroup {
    .mat-mdc-optgroup-label {
      font-size: 1.2rem;
      min-height: 3.6rem;
      font-weight: 500;
      color: $textColor;
      padding-left: 0.5rem;
    }
    .mat-mdc-option:not(.mat-mdc-option-multiple) {
      padding-left: 2rem;
    }
  }

  .mat-mdc-option {
    color: $textColorDark;
    padding: 1.5rem 1rem;
    border-radius: 1rem;

    // Multi select chip options this .multi-chip-select-option class used in ZenInputChipListSelectionComponent
    // .multi-chip-select-option - is used to to fix multi select option closing issue after selection.
    &.multi-chip-select-option {
      padding: 0rem 1.5rem !important;
      height: inherit;
      min-height: inherit;

      .mdc-list-item__primary-text, .mat-mdc-checkbox {
        width: 100%;
      }

      .mat-mdc-checkbox {
        height: 5rem;
        display: flex;
        align-items: center;
      }
    }

    .mat-pseudo-checkbox-minimal {
      display: none;
    }

    .mdc-list-item__primary-text {
      font-weight: 500 !important;
      font-size: $formFontSize !important;
      line-height: 1.275;
    }

    // Disable ripple
    .mat-ripple.mat-mdc-option-ripple {
      display: none;
    }

    &.mdc-list-item--selected .mdc-list-item__primary-text {
      color: var(--secondaryColor);
      font-size: 1.2rem;
    }

    &.mdc-list-item--disabled, &.mdc-list-item--disabled p, &.mdc-list-item--disabled p.text-md {
      color: $textColor;
    }

    /* ngx search input container design */
    &.contains-mat-select-search {
      border: 1px solid $textColorLight_2;
      border-radius: $borderRadius;
      height: 5rem;
      margin: 0.25rem 0.25rem 1rem 0rem;

      .mat-select-search-inner {
        border-bottom: none !important;
        position: relative !important;
        height: 6rem !important;
        .mat-select-search-clear {
          top: -0.7rem;
          right: 1rem;
        }

        .mat-select-search-no-entries-found {
          padding: 0 16px 16px 16px;
        }

        .mat-select-search-input {
          position: relative !important;
          height: 3rem;
          width: 100%;
          background-image: url(/assets/img/search-icon.svg);
          background-position: 1.5rem center;
          background-repeat: no-repeat;
          text-indent: 1.75rem;

          &::placeholder {
            color: $textColorLight;
          }
        }
      }
    }

    /* This is used on zen-send-email-dialog */
    &.action-button {
      background: $textColorLight_4;
      margin-bottom: 0.5rem;
      height: 5rem;

      .mat-pseudo-checkbox {
        display: none;
      }

      .mdc-list-item__primary-text {
        display: flex;
        align-items: center;

        i {
          color: $blue_1;
          background: $blue_5;
          margin-right: 1rem;
          border-radius: 0.8rem;
          padding: 0 0.015rem;
        }
      }
    }

    &.mat-mdc-option.mdc-list-item--selected, &.mat-option.mat-active, &:hover {
      background: $textColorLight_4;
    }
  }
}

.mat-autocomplete-trigger {
  font-size: $formFontSize !important;
}

/* Material select dropdown design overrides ENDS. */

/* Small dropdown with only caret. In portfolio Contract Countdown card */
.card-filter-select.mat-mdc-form-field {
  width: 8.5rem !important;
  font-size: 1.2rem !important;
  height: 2rem !important;
  top: 0.25rem;
  position: relative;
  margin-top: 0.5rem;

  .mdc-line-ripple, .mdc-floating-label, .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 !important;

    .mat-mdc-form-field-infix {
      padding: 0 !important;
      top: -0.35rem !important;
    }

    &.mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background: none;
    }

    .mat-mdc-form-field-focus-overlay {
      opacity: 0 !important;
    }
  }
}

// Due Date & Contract Start date field
.time-and-date-field.zen-input-group {
  .mat-mdc-form-field.left-field {
    width: 65%;
  }

  .mat-mdc-form-field.right-field {
    width: 35%;

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
      max-width: none !important;
      padding-left: 1rem !important;
    }

    &.mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__leading {
      min-width: 0rem;
    }
  }
}

// Months input used in the add/edit contract-form dialog
.month-input.zen-input-group {
  .left-field {
    min-width: calc(100% + -8.5rem) !important;
  }
}

// To make the dropdown input search field sticky. These select-search-input-wrapper used in the app-zen-select-search-dropdown
// The sticky dropdown search ref is add/edit serviceAddress type field
.select-search-input-wrapper {
  position: sticky;
  top: 0rem;
  background: white;
  z-index: 1;
  margin-bottom: 5rem;

  .select-search-input-container {
    position: absolute;
    top: -1rem;
    background: white;
    width: 100%;
    padding: 1rem 0 0 0;
  }
}

.mat-form-field-info-icon {
  color: $blue_1;
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1.4rem;
  position: relative;
  top: 1.65rem;
  height: fit-content;
}
