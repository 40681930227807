@import "zen-colors";

.zen-breadcrumb-main {

  .mat-mdc-menu-content {
    padding: 1rem;
  }

  .zen-breadcrumb-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 3rem;
    border-radius: 0.8rem;
    transition: all .2s ease;

    &.mat-mdc-menu-item .mat-mdc-menu-item-text {
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    p {
      font-size: 1.4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i, p {
      color: $textColorLight;
      font-weight: 400;
      margin: 0 0.5rem 0 0;
    }

    &:hover {
      background-color: $textColorLight_4 !important;
    }

    &.current, &:hover {
      p, i:not(.current-icon) {
        color: $textColorDark;
      }
    }

    i.current-icon {
      color: $success_2;
      min-width: 1.5rem;
      margin-right: 1rem;
      font-size: 1.2rem;
    }
  }

}
