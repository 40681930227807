@import "zen-colors";

i.color-icon {
  padding: 1.125rem;
  border-radius: 0.75rem;
  background: $textColorLight_3;
  color: $textColor;
  min-width: 2.8rem;
  min-height: 2.8rem;
  text-align: center;

  &.small {
    padding: 0.5rem;
    min-width: 2rem;
    min-height: 2rem;
  }

  &.color-icon--small{
    padding: 0rem;
    min-width: 0.2rem;
    min-height: 0.2rem;
    font-size: 2rem;
    background: transparent;
    color: $blue_1;
    margin-right: 0.5rem;
    vertical-align: middle;
    // Apply rotation only when 'rotate' class is also present
    &.rotate {
      transform: rotate(-45deg);
    }
  }

  &.checked, &.info {
    background: $blue_1;
    color: white;
    border-radius: 0.5rem;
  }

  &.not-selected {
    background: transparent;
    color: transparent;
    border: 2px solid $textColorLight_2;
    color: $textColorDark;
    border-radius: 0.5rem;
  }

  &.circle {
    border-radius: 50%;
  }

  &.action-btn {
    border: 1px solid $blue_1;
    color: $blue_1;
    background: transparent;
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include zenStatusColors;
}
