@import "zen-colors";

table.zen-custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    tr th {
      background: $textColorLight_4;
      color: $textColorLight;
    }
  }
  tbody {
    tr td {
      border-bottom: 1px solid $textColorLight_3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 15rem;
    }
  }

  thead {
    tr:first-child th:first-child {
      border-top-left-radius: 1rem;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 1rem;
    }
  }

  tbody {
    tr:last-child td:first-child {
      border-bottom-left-radius: 1rem;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 1rem;
    }
  }
}


$fontSize: 1.3rem;

table.bill-calculator-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    tr th {
      border: none;
      color: $textColorLight;
      font-size: 1rem;
      background: transparent;
      padding: 0 0 0.25rem 1rem !important;
      font-weight: 400;
      text-align: center;
    }
  }
  tbody {
    tr td {
      border: 0.1rem solid $textColorLight_2;
      text-align: center;
      width: 10rem;
      font-size: $fontSize;

      &.td-input {
        padding: 0 !important;
        input.edit-field {
          font-size: $fontSize;
          border: none;
          background: transparent;
          text-align: center;
          width: 100%;
          padding: 1rem !important;

          /* Remove Arrows/Spinners */
          /* Chrome, Safari, Edge, Opera */
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type=number] {
            -moz-appearance: textfield;
          }
        }

        &.invalid {
          border-color: $danger_1;
        }
      }
    }

    tr {
      td {
        &:nth-child(3) {
          color: $textColor;
          background-color: $textColorLight_4;
        }
      }
      &:last-child td {
        background-color: $textColorLight_4;
        td {
          color: $textColorDark;
        }
      }
    }

    tr:first-child td:first-child { border-top-left-radius: 10px; }
    tr:first-child td:last-child { border-top-right-radius: 10px; }

    tr:last-child td:first-child { border-bottom-left-radius: 10px; }
    tr:last-child td:last-child { border-bottom-right-radius: 10px; }
  }
}


