@import "zen-colors";

$boxShadow: 0px 8px 18px 8px rgb(0 0 0 / 2%);

/* This should included inside card-snapshot class. */
@mixin snapCardSmallVersion {
  background: transparent;
  box-shadow: none;
  margin: 0;
  padding: 0.5rem 0 1rem 0;
  min-height: auto;

  .snapshot-main {
    .snap-card {
      background: white;
      padding: 1.25rem 1rem;
      box-shadow: $boxShadow;
    }
  }

  @media only screen and (min-width: 390px) and (max-width: 728px) {
    min-width: 100%;
    display: block;
    padding: 0 !important;

    .snapshot-main {
      display: flex;
      flex-direction: column;

      .top-sec {
        padding-bottom: 2rem !important;
      }
      .bottom-sec {
        padding-bottom: 0rem;
      }
      .bottom-sec, .top-sec {
        width: 100%;
        display: flex;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    min-width: 100%;
    display: block;
    .card-title-main {
      margin-left: 1.7rem;
    }

    .snapshot-main {
      display: flex;

      .top-sec, .bottom-sec {
        padding-bottom: 0rem;
      }

      .bottom-sec, .top-sec {
        width: 100%;
        display: flex;
      }
    }
  }
}


.card-container-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: $textColorDark;

  .card-container {
    background: white;
    padding: 2rem 2.5rem 1.5rem 2.5rem;
    border-radius: 1rem;
    min-width: 23.5rem;
    clear: both;
    display: inline-block;
    margin: 1rem;
    box-shadow: $boxShadow;

    &:not(.no-flex) {
      flex: 1 0 100px;
    }

    &.fit-content {
      flex: initial;
    }

    &.pf-tabs-main-wrapper {
      padding: 2.5rem 2.5rem 0 2.5rem;
    }

    &.zen-table-container {
      min-height: 55rem;
    }

    .card-title-main {
      margin-bottom: 2rem;

      .card-title-wrapper {
        display: flex;
        align-items: center;
      }
    }

    &.wd-small {
      min-width: 26.5rem;
      max-width: 40rem;
    }

    .snapshot-main {

      .top-sec, .bottom-sec {
        display: flex;
        justify-content: space-between;
      }

      .snap-card {
        display: flex;
        border-radius: 1.25rem;

        .icon-main {
          font-size: 1.6rem;
          padding: 0.45rem;
          color: $textColorLight;
          background: $textColorLight_3;
          border-radius: 1.5rem;
          height: 2.625rem;
          margin-right: 1rem;
        }
      }
    }
  }

  @media only screen and (min-width: 390px) and (max-width: 727px) {
    justify-content: center;
    .card-container {
      &.pf-tabs-main-wrapper {
        margin-right: 0;
        margin-left: 0;
      }
      &.wd-small {
        min-width: 100%;
      }

      &.card-snapshot {
        @include snapCardSmallVersion;
      }
    }
  }

  @media only screen and (min-width: 728px) and (max-width: 1023px) {
    .card-container {
      &.wd-small {
        min-width: 45%;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    justify-content: center;
    .card-container.wd-small {
      &.card-snapshot {
        @include snapCardSmallVersion;
      }
    }
  }

  .card-container {
    &.card-snapshot .snapshot-main .top-sec {
      padding-bottom: 4rem;
      padding-top: 0rem;
    }

    .legend-sec {
      padding-right: 1.7rem;
    }

    @media only screen and (min-width: 1366px) and (max-width: 1465px) {
      .legend-sec {
        padding-right: 0 !important;
      }
    }

    &.with-filter {
      .legend-sec {
        padding-right: 0.7rem;
      }

      @media only screen and (max-width: 389px) {
        .card-title-main {
          margin-bottom: 0;
        }
      }
      @media only screen and (min-width: 390px) and (max-width: 1023px) {
        .card-title-main {
          display: flex;
          justify-content: space-between;
          padding-right: 0.95rem;
        }
      }
      @media only screen and (min-width: 1024px) and (max-width: 1365px) {
        .card-title-main {
          margin-bottom: 0;
        }
      }
      @media only screen and (min-width: 1024px) and (max-width: 1700px) {
        .card-title-main {
          position: relative;
          margin-bottom: 2rem;

          .mat-mdc-form-field.card-filter-select {
            position: absolute;
            right: 1.25rem;
            top: 1.25rem;
            @media only screen and (min-width: 1366px) and (max-width: 1465px) {
              right: 0.75rem;
            }
          }
        }
      }
      @media only screen and (min-width: 1701px) {
        .legend-sec {
          padding-right: 0.5rem;
        }
        .card-title-main {
          display: flex;
          justify-content: space-between;
          padding-right: 0.75rem;
          margin-bottom: 1.25rem;
        }
      }
    }
  }


  /* To enable normal 4x4 cards responsiveness. Used in customer details. */
  &.four-cards-responsive {
    @media only screen and (min-width: 390px) and (max-width: 727px) {
      .card-container {
        margin: 1rem 0.25rem;
        min-width: 100%;
        padding: 2rem 2.5rem 2rem 2.5rem;
        &.pf-tabs-main-wrapper {
          padding: 2.5rem 2.5rem 0 2.5rem;
        }
      }
    }
    @media only screen and (min-width: 728px) {
      .card-container {
        margin: 1rem;
      }
    }
    @media only screen and (min-width: 728px) and (max-width: 1365px) {
      .card-container {
        min-width: 40%;
      }
    }
    @media only screen and (min-width: 1366px) {
      .card-container {
        min-width: 19rem;
      }
    }
  }

  /* To enable normal 3x3 cards responsiveness. Used in meter details. */
  &.three-cards-responsive {
    @media only screen and (max-width: 727px) {
      .card-container {
        min-width: 100%;
      }
    }
    @media only screen and (min-width: 728px) and (max-width: 1249px) {
      .card-container {
        min-width: 40%;
      }
    }
  }

}

.card-border__text-light {
  border-radius: 0.4rem;
  border-color: $textColorLight;
  border-width: 0.05rem;
  border-style: solid;
}
