@import "./zen-colors";
@import "./zen-mat-form";
//@import "src/scss/shared";
/* Ref: https://stackblitz.com/edit/angular-ngx-popper-egiyne?file=src%2Fapp%2Fapp.component.ts */

.zen-popper, .ngxp__container {
  color: $textColorDark;
  font-size: 1.2rem;
  background: white;
  border-radius: 0.5rem !important;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1) !important;
  display: none;
  z-index: 100;
  border: none !important;
  padding: 0 !important;

  .list-data {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .list {
      margin-left: 2rem;
    }

    &::before {
      content: "•";
      font-size: 3rem;
      position: absolute;
      top: -1.35rem;
      color: $textColorDark;
    }

    &.xs-bullet::before {
      content: "•";
      font-size: 1.5rem;
      position: absolute;
      top: -0.125rem;
      color: $textColorDark;
    }

    &.info_1::before, &.processing::before {
      color: $info_1;
    }

    &.danger_1::before, &.expired::before {
      color: $danger_1;
    }

    &.danger_2::before, &.expiring-soon::before {
      color: $danger_2;
    }

    &.danger_3::before, &.expiring::before {
      color: $danger_3;
    }

    &.success_1::before, &.contracted::before {
      color: $success_2;
    }

    &.success_4::before, &.future::before {
      color: $success_4;
    }

    &.warn_1::before, &.on-utility::before {
      color: $warn_1;
    }

    &.undefined::before {
      color: $textColorLight_2;
    }
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: $textColorDark;
  }

  &.xs {
    max-width: 25rem;
    width: -webkit-fill-available;
  }

  &.min-xs {
    min-width: 25rem;
    max-width: 25rem;
    width: -webkit-fill-available;
  }

  &.sm {
    max-width: 38rem;
    width: -webkit-fill-available;
  }

  &.min-sm {
    min-width: 38rem;
    max-width: 38rem;
    width: -webkit-fill-available;
  }

  &.md {
    max-width: 50rem;
    width: -webkit-fill-available;

    @media screen and (max-width: 628px) {
      max-width: 28rem;
    }
  }

  &.zen-confirmation-popper {
    border-radius: 1.5rem !important;
    padding: 4rem !important;
    max-width: 55rem;
    width: -webkit-fill-available;

    .close-icon {
      position: absolute;
      left: 1.5rem;
      top: 1.5rem;
      font-size: 1.75rem;
      cursor: pointer;
    }
  }

  // This one wont maintain constant width adjust w.r.t the content. But still maintain max-width: 38rem
  // Used in table header info popper message text
  &.max-sm {
    max-width: 40rem;
  }

  &.sm-2 {
    max-width: 42rem;
  }

  &.bg-transparent {
    background: rgba($textColorDark, 0.8) !important; //$textColorDark;
    .ngxp__arrow:before {
      background-color: transparent;
    }
  }

  /* This is used in the app-zen-table column info text popper. */
  .bg-dark-transparent {
    color: white;
    padding: 1.5rem;

    p {
      color: white !important;
      margin: 0;
    }
  }

  /* The below popper styles are used on ZenPopperTempComponent */
  .zen-popper-main {
    position: relative;
    text-align: left;

    .zen-tooltip-content-wrapper {
      padding: 2.125rem !important;
      p {
        color: $textColorDark;
        font-size: 1.2rem;
      }

      .close-icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 1.6rem;
        cursor: pointer;
        color: $textColorDark;
      }
      &.small {
        padding: 1.5rem !important;
      }
    }

    .zen-popper-data-wrapper {
      padding: 1rem 0;
      .zen-popper-data-main {
        padding: 1rem 2.25rem;
        position: relative;
        cursor: pointer;
        cursor: default;
        &.center {
          padding-left: 4.3rem;
        }

        .value {
          color: $textColorDark;
          font-size: 1.2rem;
          margin-bottom: 0.125rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          .status-indicator {
            font-size: 1.2rem;
            &.active {
              color: $success_2;
            }
            &.inactive {
              color: var(--textColorLight45);
            }
          }
        }

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          font-size: 1rem;
          color: $textColor;
        }

        .title i {
          margin-right: 0.25rem;
          font-size: 1.2rem;
          color: $textColor;
        }

        .copy-icon {
          display: none;
          font-size: 1.5rem;
          position: absolute;
          right: 1rem;
          top: 1.25rem;
          cursor: pointer;
          color: $textColorDark;
          &.material-symbols-rounded {
            font-variation-settings:
              'FILL' 0,
              'wght' 200,
              'GRAD' 200,
              'opsz' 48
          }
        }

        &:hover {
          background: $textColorLight_4;

          .copy-icon {
            display: block;
          }
        }
      }
    }
  }
}

.help-outline {
  color: $info_1;
  cursor: pointer;
  font-size: 1.25rem;
}

// mat tooltip no-wrap
.mdc-tooltip.no-wrap {
  .mdc-tooltip__surface {
    max-width: unset !important;
  }
}

.popper-scroll {
  max-height: 11.75rem;
  overflow-y: scroll;
  @include ZenCustomScrollBar;

  &.bg-dark-transparent {
    max-height: 13.25rem;

    &::-webkit-scrollbar {
      width: 1.25rem;
    }

    &::-webkit-scrollbar-thumb {
      border: 0.35rem solid rgba(64, 70, 77, 0.8) !important;
    }
  }
}
