.mat-mdc-radio-button {
  .mdc-radio {
    padding: 0;
    scale: 0.75;

    &.mdc-radio--disabled {
      opacity: 0.4;
    }
  }

  .mdc-label {
    font-size: 1.4rem;
  }
}
