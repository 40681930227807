// Generics
$headerFontFamily: 'Open Sans', sans-serif;
$bodyFontFamily: 'Open Sans', sans-serif;

/* Material Design Icons */
.material-icons {
  font-weight: 400;
  font-size: 1.25em; /* Preferred icon size */
  line-height: 1;
  -webkit-font-smoothing: antialiased; /* Support for all WebKit browsers. */
  text-rendering: optimizeLegibility; /* Support for Safari and Chrome. */
  -moz-osx-font-smoothing: grayscale; /* Support for Firefox. */
  font-feature-settings: 'liga'; /* Support for IE. */
}

/* YELLOW color variations */
$warningDark: #E6AE00;
$warning1: #FFCC00;
$warning2: #FFCA26;
$warning3: #FFD34C;
$warning4: #FFDD73;
$warning5: #FFE699;
$warning6: #FFF3CC;
/* BLUE color variations */
$infoDark: #007ACC;
$info1: #0099FF;
$info2: #26A8FF;
$info3: #4CB7FF;
$info4: #73C7FF;
$info5: #99D6FF;
$info6: #CCEBFF;
/* RED color variations */
$dangerDark: #BD240F;
$danger1: #F05A46;
$danger2: #F27362;
$danger3: #F48B7D;
$danger4: #F7A59A;
$danger5: #F9BDB5;
$danger6: #FCDEDA;
/* GREEN color variations */
$successDark: #116E40;
$success1: #199e5c;
$success2: #47B17D;
$success3: #5EBB8C;
$success4: #81CAA6;
$success5: #A3D8BE;
$success6: #D1ECDE;

$borderRadius: 3px;
// currently used in rate check report and used to add constraints for the market check popups.
// this size defines the Main screen content (excluding the left hand nav)
$mainContentMaxWidth: 1980px;

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

@mixin circle($width, $color) {
  width: $width;
  height: $width;
  background: $color;
  -webkit-border-radius: $width/2;
  -moz-border-radius: $width/2;
  border-radius: $width/2;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

/// Slightly lighten
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// ********************
// fadeIn
// ********************
$fadeinPrefix: '-moz-', '-webkit-', '-o-', '-ms-', '';
@mixin keyframe-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-webkit-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-o-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-ms-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@keyframes fadeIn {
  @include keyframe-fadeIn;
}
@mixin fadeIn($arg) {
  $keyframe-name: fadeIn;
  $duration: $arg;
  @each $p in $fadeinPrefix {
    #{$p}animation: $keyframe-name $duration;
  }
}
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin logo {
  background-image: var(--logoPrimaryDark);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: visible;
}

@mixin icon {
  border-style: solid;
  border-width: 1px;
  border-color: var(--textColorLight45);
  border-radius: 50%;
  text-align: center;
  padding: 0.5em 0.45em;
  color: var(--primaryColor);
  font-size: 1em;
  width: 2.5em;

  i {
    color: var(--primaryColor);
    vertical-align: middle;
  }
}

@mixin upper-subtitle {
  font-family: $headerFontFamily;
  font-size: 0.875em;
  font-weight: 600;
  color: var(--textColorDark15);
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

p {
  color: var(--textColor);
}

a {
  color: var(--secondaryColor);
}

h3,
.h3 {
  font-size: 1.25em;
  font-weight: 600;
  margin: 1em 0;
}

button:disabled {
  opacity: 0.5;
}

a:link, a:visited {
  color: var(--secondaryColor);
}

a:hover, a:active, a:focus{
  color: var(--secondaryColorDark20);
  text-decoration: none;
}

.hidden {
  display: none;
}

/** Card styles - used in onboarding page */
.card {
  background-color: var(--primaryColorLight95);
  -webkit-box-shadow: 0 0.25em 0.5em 0.25em var(--textColorLight65);
  -moz-box-shadow: 0 0.25em 0.5em 0.25em var(--textColorLight65);
  box-shadow: 0 0.25em 0.5em 0.25em var(--textColorLight65);
  border: none !important;
  border-radius: 5px;
  margin: 0 2em 2em 0;
  padding: 1em;

  .big-select {
    .material-icons {
      color: var(--primaryColor);
      font-size: 80px;
    }
  }

  .card-title {
    text-transform: uppercase;
    font-weight: 600;
  }
}


.search-icon {
  right: 2em;
  font-size: 0.875em;
}

.card-content {
  .table thead > tr > th {
    border-top: none;
  }
}

.checkerboard-bg {
  background-image: url(/assets/img/checkerboard.png);
  background-repeat: repeat;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.page-title {
  position: relative;
  color: var(--textColorDark35);
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}
