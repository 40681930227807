@import "zen-colors";

.mat-mdc-progress-spinner {
  svg circle {
    stroke: $textColor;
  }
  &.mat-primary {
    svg circle {
      stroke: var(--primaryColor) !important;
    }
  }
  &.mat-accent {
    svg circle {
      stroke: var(--secondaryColor) !important;
    }
  }
  &.mat-blue {
    svg circle {
      stroke: $blue_1 !important;
    }
  }

  &.mat-default {
    --mdc-circular-progress-active-indicator-color: #8597A2;
  }
}
