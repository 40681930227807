@import "zen-colors";

.mat-datepicker-popup.cdk-overlay-pane {
  background: white;
  box-shadow: 0px 8px 18px 8px rgb(0 0 0 / 2%);

  .mat-datepicker-content .mat-calendar {
    height: auto;
    margin-bottom: 2rem;

    .mat-calendar-header .mat-calendar-controls {
      align-items: center;
      button {
        .mdc-button__label {
          color: $textColorDark;
          font-weight: 600;
        }
        &.mat-calendar-period-button {
          padding-left: 1.25rem !important;
        }
      }
    }
  }
  .mat-calendar-content .mat-calendar-table {
    thead.mat-calendar-table-header {
      th {
        background: transparent;
        &.mat-calendar-table-header-divider {
          border-top: 1px solid $textColorLight_2;
        }
      }
    }
    tbody.mat-calendar-body {
      tr td.mat-calendar-body-cell-container {
        padding: 1.5rem 1.5rem !important;
        .mat-calendar-body-cell-content {
          border-width: 0;
          width: 75%;
          height: 100%;
          padding: 1.25rem 1.25rem !important;
          margin: 0.5rem !important;

          &:hover {
            border-width: 1px;
          }

          &.mat-calendar-body-selected {
            background: var(--secondaryColorLight15);
            font-weight: bold;
            color: white;
          }
        }

        &:hover .mat-calendar-body-cell-content {
          background: var(--primaryColorLight15);
          color: white;
        }
      }
    }
  }
}

.mat-date-range-input.mat-mdc-input-element {
 font-size: 1.2rem !important;
  line-height: 2 !important;
}
