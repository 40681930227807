@import 'shared';
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp|Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,300,0,0");
/* used for signing */
@import url("https://fonts.googleapis.com/css?family=La+Belle+Aurore");
@import "themes/zen-material/components/zen-colors";
@import '../../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';


* {
  font-family: $bodyFontFamily;
}

h1, h2, h3, h4, h5, h6, p, label {
  font-family: $headerFontFamily;
}

body,
html {
  color: var(--textColor);
  font-size: 14px;
  height: 100%;
}
body {
  background: var(--textColorLight70);
}

.main {
  height: 100%;
}

a {
  font-weight: 600;
  outline: none;
  color: var(--secondaryColor);
}

a:hover, textarea, select, input, button, table, td, th, div {
  outline: none;
}

button:focus, textarea:focus, select:focus, input:focus {
  outline: 0;
  box-shadow: none;
}

h1 {
  font-weight: 500;
  font-size: 14px/1.5;
  color: var(--textColor);
  padding: 0;
  margin: 0;
}

h3, .h3 {
  font-size: 1.25em;
  font-weight: 600;
  margin: 1em 0;
}

p {
  font-weight: 400;
  font-size: 1.4rem;
  color: $textColorDark;
  padding: 0;
  margin: 0 0 1em;
  line-height: 160%;
}

.full-width {
  width: 100%;
}

.tooltip {
  display: none !important;
}

label {
  margin-bottom: 0;
}

table {
  tr th {
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-align: left;
    border: none;
    background: var(--textColorLight60);
    padding: 1rem !important;
    width: auto;
    vertical-align: top;

    a {
      color: var(--textColor);
    }
  }

  tr td {
    font-style: normal;
    font-stretch: normal;
    text-align: left;
    border: none;
    padding: 1rem !important;
    width: auto;
    background: var(--primaryColorLight95);
    vertical-align: top;
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.va-t {
  vertical-align: top;
}

mark, .mark {
  background-color: var(--primaryColorLight40);
  padding: 0.1em 0;
}

.hide {
  display: none;
}

.medium {
  font-size: 0.9em;
}

.medium-2 {
  font-size: 1.4rem;
}

.large {
  font-size: 1.5em;
}

.error-text {
  color: red;
}

.pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.text-muted {
  color: $danger1 !important;
  font-weight: 500;
}

.border-radius-4 {
  border-radius: 4px !important;
}
