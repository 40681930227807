@import "zen-colors";
.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5rem !important;
    background-color: transparent !important;
  }

  &.success-toast .mdc-snackbar__surface {
    background: $success_2 !important;
  }

  &.warn-toast .mdc-snackbar__surface {
    background: $danger_2 !important;
  }

  .zen-toast .mdc-snackbar__surface {
    color: white;
  }

  // zen-toast-help component with jen
  &.zen-toast-help, &.zen-toast-with-popper {
    box-shadow: none !important;
    background: transparent;
    min-width: 50vw;
    max-width: initial;

    .mdc-snackbar__surface {
      box-shadow: none !important;
    }

    .zen-toast-main {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: right;

      .message-container {
        background: white;
        border-radius: 2rem;
        padding: 2.5rem 10rem 2.5rem 5rem;
        position: relative;
        left: 7.5rem;
        max-width: 62rem;
        box-shadow: 0px 8px 18px 8px rgba(0, 0, 0, 0.02);

        .close-icon {
          color: $textColorDark;
          cursor: pointer;
          position: absolute;
          left: 1.5rem;
          top: 1.5rem;
        }
      }

      .img-container {
        z-index: 1;
        position: relative;
        cursor: pointer;

        .count {
          color: white;
          background: $danger_1;
          border-radius: 50%;
          position: absolute;
          font-size: 1.4rem;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 2rem;
        }
      }
    }
  }

  &.zen-toast-help {
    margin: 0;
    padding: 0;
    position: relative;
    top: 3.5rem;
    right: 4rem;
  }

  &.zen-toast-with-popper {
    min-width: auto;
    position: fixed;
    bottom: 0rem;
    right: 0rem;
  }

}
